export const Permissions = {
    Operate: 310,
    Override: 320,
    RecieveNotices: 330,
    RecieveWarnings: 340,
    ViewCycles: 350,
    ViewStatus: 360,
    ViewLog: 370,
    ViewUsers: 380,
    ManageInfo: 390,
    ManageInvites: 400,
    ManageUsers: 410,
    ManageSchedules: 420,
    ManageUserPermissions: 430,
    ManageSubscription: 440,
    ManageMonitoring: 450
}

export const DefaultGateUserPermissions = [           
    {
        permission: 310,
        name: 'Operate gate',
        description: 'Be able to manually close or open gate',
        value: false
    },
    {
        permission: 320,
        name: 'Override gate',
        description: 'Be able to force driving gate',
        value: false
    },
    {
        permission: 330,
        name: 'Recieve notices',
        description: 'Get notified by email on gate events',
        value: false
    },
    {
        permission: 340,
        name: 'Recieve warnings',
        description: 'Get notified by email on gate warnings',
        value: false
    },
    {
        permission: 350,
        name: 'View gate cycles',
        description: 'Be able to view number of gate cycles.',
        value: false
    },
    {
        permission: 360,
        name: 'View gate status',
        description: 'Be able to view if gate is open or closed etc.',
        value: false
    },
    {
        permission: 370,
        name: 'View gate log',
        description: 'Be able to read past gate events',
        value: false
    },
    {
        permission: 380,
        name: 'View gate users',
        description: 'Be able to view who are connected to this aprticular gate',
        value: false
    },
    {
        permission: 390,
        name: 'Manage gate info',
        description: 'Be able to manage gate info',
        value: false
    },
    {
        permission: 400,
        name: 'Manage gate invites',
        description: 'Be able to manage gate invites',
        value: false
    },
    {
        permission: 410,
        name: 'Manage gate users',
        description: 'Be able to manage who are connected to this gate',
        value: false
    },
    {
        permission: 420,
        name: 'Manage gate schedules',
        description: 'Be able to manage schedules for this gate',
        value: false
    },
    {
        permission: 430,
        name: 'Manage User Permissions',
        description: 'Be able to change the authority of other users (these permissions)',
        value: false
    },
    {
        permission: 440,
        name: 'Manage Subscription',
        description: 'Be able to manage subscription level for this gate',
        value: false
    },
    {
        permission: 450,
        name: 'Manage Monitoring',
        description: 'Be able to manage monitoring level for this gate',
        value: false
    },
]
import axios from 'axios';
import { setAuthHeader, resetAuthHeader } from './axiosHeaders';
import accountService from '@/services/accountService';

const config = {
    userInfoUri: `/api/account/userinfo`,
};

const state = {
    user: {
        //authenticated: axios.defaults.headers.common['Authorization'] != null ? true : false,
        authenticated: false,
    },
    token: localStorage.getItem('auth-token'),
    refreshToken: localStorage.getItem('refresh-token')

};

const getters = {
    hasToken: (state) => !!state.token,
    isAuthenticated: (state) => !!state.user.authenticated,
    user: (state) => state.user,
    token: (state) => state.token,
    refreshToken: (state) => state.refreshToken,
};

const actions = {
    validatePermission({ commit, dispatch }, payload) {
        return payload.indexOf(this.state.authentication.user.userRole) !== -1;
    },
    validateAccessToken({ commit, dispatch }, payload) {
        return accountService.validateAccessToken(payload)
            .then((result) => {
                if (result.message === "Success") {

                    axios.defaults.headers.common['Authorization'] = payload ? 'Bearer ' + payload : '';

                    axios.get(config.userInfoUri).then((result) => {
                        commit("setUser", result.data);
                    });
                    commit('setToken', payload);
                }
                console.log(result);
                return result.message;

            })
            .catch((error) => {
                console.log('LogIn Error:' + error);
                return false;
            });
    },

    hasGatePermission({ dispatch }, payload) {
        for (let i in this.state.authentication.user.gates) {
            if (this.state.authentication.user.gates[i].id == payload.gateId) {
                return this.state.authentication.user.gates[i].userGatePermissions.includes(payload.permission);
            }
        }
        return false;
    },

    login({ commit, dispatch }, user) {
        return accountService.post('loginWebClient', user)
            .then((result) => {
                commit("setToken", result.data.accessToken);
                commit('setRefreshToken', result.data.refreshToken);
                return dispatch('getProfile').then((userData) => {
                    if (!userData) {
                        return false;
                    }
                    return true;
                });

            })
            .catch((error) => {
                commit('setToken', null);
                commit('setRefreshToken', null);
                //console.log('LogIn Error:' + error);
                return false;
            });
    },

    logout({ commit }) {
        let user = {
            authenticated: false,
            userRoles: []
        };
        commit('logout', user);
    },

    register({ dispatch }, user) {
        var result = false;

        dispatch('logout');

        return accountService.post('registerUser', user)
            .then((response) => {
                let newUser = {
                    username: user.email,
                    password: user.password
                }

                return dispatch('login', newUser).then(() => {
                    return true;
                });
            })
            .catch((error) => {
                //console.log(error);
                return false;
            });
    },

    forgot({ commit }, email) {
        return accountService.post('forgotPassword', email).then((ret) => {
            return ret;
        });
    },

    getProfile({ commit }) {
        let token = this.state.authentication.token;

        if (token) {
            axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : '';

            return axios.get(config.userInfoUri).then((result) => {
                commit("setUser", result.data);
                return result.data
            });
        }

        return false;
    }
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('auth-token', token);
        setAuthHeader(token);
    },
    setRefreshToken(state, refreshToken) {
        state.refreshToken = refreshToken;
        localStorage.setItem('refresh-token', refreshToken);
    },
    logout(state, user) {
        state.user = user;
        state.token = null;
        localStorage.removeItem('auth-token');
        localStorage.removeItem('refresh-token');
        resetAuthHeader();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
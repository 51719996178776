export const NotificationType = {
    ClampProtectionClosing: 1,
    ClampProtectionOpening: 2,
    EngineProtection: 4,
    TimeForService: 8,
    EmergencyStop: 16,
    ForcedClosingGate: 32,
    UserRequests: 64
};

export const DefaultGateNotificationTypes = [
    {
        type: NotificationType.ClampProtectionClosing,
        name: 'Clamp protection closing',
        value: false
    },
    {
        type: NotificationType.ClampProtectionOpening,
        name: 'Clamp protection opening',
        value: false
    },
    {
        type: NotificationType.EngineProtection,
        name: 'Engine protection',
        value: false
    },
    {
        type: NotificationType.TimeForService,
        name: 'Time for service',
        value: false
    },
    {
        type: NotificationType.ForcedClosingGate,
        name: 'Force closed gate',
        value: false
    }
]